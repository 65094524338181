<template>
  <div class="login">
    <div class="logo text-center">
      <a href="https://www.dentroeduca.com.br/" target="_blank"
        ><img
          width="127"
          :src="require('@/assets/primary-1.svg')"
          alt="Dentro Educa"
          class
      /></a>
    </div>

    <h3 v-if="!success">Esqueci minha senha</h3>
    <h5 v-if="!success">Digite seu e-mail para receber um e-mail</h5>

    <form @submit.prevent="doRecover" class="va-row">
      <div class="flex xs12" v-if="!success">
        <div class="input-group">
          <input-normal
            type="text"
            label="Seu e-mail"
            id="username"
            name="username"
            :disabled="false"
            maxlength="50"
            :validate="'required'"
            :value="login.username"
            @input="login.username = $event.target.value"
          />
        </div>
      </div>

      <div class="d-flex align--center justify--space-between flex xs12">
        <button class="btn btn-primary blue" type="submit" v-if="!success">
          Enviar
        </button>
      </div>

      <div class="error" v-if="errors.length > 0">{{ errors[0].message }}</div>

      <div class="flex xs12">
        {{ success }}
      </div>
      <br />

      <router-link
        class="flex xs12 text-center font-back-blue"
        :to="{ name: 'main' }"
      >
        VOLTAR PARA LOGIN
      </router-link>
    </form>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
const axios = require("axios");

export default {
  name: "login",

  data() {
    return {
      login: {
        username: null,
      },
      success: null,
    };
  },

  methods: {
    doRecover() {
      const self = this;

      this.$validator.validateAll().then((result) => {
        axios
          .post(process.env.VUE_APP_ROOT_API + "/users/recovery", self.login)
          .then(function (response) {
            //self.$router.push({ name: 'login' })
            self.success = response.data.message;
            //alert("Sucesso");
          })
          .catch(function (error) {
            // console.log(error)
            this.errors.push(error);
          });
      });
    },
  },
};
</script>

<style lang="scss">
.login {
  font-family: "Nunito";
  width: 35rem;
  text-align: center;

  @include media-breakpoint-down(md) {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
    .down-container {
      display: none;
    }
  }

  h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: 1.33px;
    text-align: center;
    color: #68596e;
    font-weight: 800;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #afa2ba;
    margin-bottom: 30px;
  }

  .btn-primary {
    width: 100%;
    //font-family: "Nunito";
    font-size: 14px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 4;
    letter-spacing: 0.8px;
    text-align: center;
    color: #ffffff;
    border-radius: 40px;
    text-transform: uppercase;
    margin-top: 30px;
  }
  .down-container {
    margin-top: 3.125rem;
  }
  .blue {
    background-image: none !important;
    background-color: #4965bc !important;
  }
  .font-back-blue {
    font-family: "Nunito";
    font-size: 14px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    color: #4965bc;
  }
}
</style>
